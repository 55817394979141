<template>
  <loader v-bind="{ loading }">
    <columns>
      <column>
        <page-heading 
          :heading="team.name" 
          :sub-heading="`At ${team.branch.name}, lead by ${team.leader.full_name}.`"
        />
      </column>
      <column class="is-narrow">
        <tabs>
          <tab-link name="team-manager" :params="{ team: team.uuid }">Overview</tab-link>
          <tab-link name="team-work-orders" :params="{ team: team.uuid }">Work Orders</tab-link>
          <tab-link name="team-members" :params="{ team: team.uuid }">Members</tab-link>
          <tab-link name="team-preferences" :params="{ team: team.uuid }">Preferences</tab-link>
        </tabs>
      </column>
    </columns>
    <router-view />
  </loader>
</template>
<script>
import { mapGetters } from 'vuex'
export default {

  data: () => ({
    loading: true
  }),

  async beforeCreate() {
    await this.$store.dispatch('team/loadTeam', this.$route.params.team)
    this.loading = false
  },

  beforeDestroy() {
    this.$store.commit('team/clearTeam')
  },

  computed: mapGetters('team', [
    'team'
  ])

}
</script>